<template>
  <validation-observer v-slot="{ invalid }">
    <option-card
      :p1="ic.negotiatorOptionTitle"
      :p2="ic.negotiatorOptionContent"
      :p3="ic.negotiatorOptionButton"
      label1="Option Title*"
      titleLabel="Negotiator Option"
      label2="Option Content*"
      label3="Option Button*"
      imgSrc="@/assets/negotiator_option_guide.png"
      imgAlt="Negotiator Option Guide"
      :passedRules="negotiatorRuleObj"
      emitName="negotiator-option-updated"
      @negotiator-option-updated="updateNegotiatorOption"
    />
    <div class="field">
      <p class="content"><em>* indicates required field</em></p>
    </div>
    <div class="field">
      <div class="buttons">
        <button :disabled="invalid" class="button is-success" @click="save">
          Save
        </button>
        <button :disabled="invalid" class="button is-info" @click="saveNext">
          Save and Next
        </button>
        <button class="button is-light" @click="revert">
          Revert
        </button>
      </div>
    </div>
    <div class="field">
      <progress-bar />
    </div>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ProgressBar from "@/components/ProgressBar.vue";
import OptionCard from "@/components/instance/OptionCard.vue";
import saveRevert from "@/mixins/negotiatorMixins";
export default {
  mixins: [saveRevert],
  components: {
    OptionCard,
    ValidationObserver,
    ProgressBar
  },
  data() {
    return {
      ic: {
        negotiatorOptionTitle: null,
        negotiatorOptionContent: null,
        negotiatorOptionButton: null
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.$store.commit("SHOW_PROGRESS");
      vm.$store.commit("HIDE_NOTIFICATION");
      try {
        await vm.fetchPaymentPortalInstanceConfig(vm.$route.params.id);
        await vm.fetchInstances();
        vm.loadView.call(vm);
      } catch (e) {
        vm.$store.commit(
          "SHOW_NOTIFICATION",
          `Error loading InstanceNegotiator; ${e}`
        );
      } finally {
        vm.$store.commit("HIDE_PROGRESS");
      }
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.$store.commit("SHOW_PROGRESS");
    this.$store.commit("HIDE_NOTIFICATION");
    try {
      await this.fetchPaymentPortalInstanceConfig(to.params.id);
      this.loadView();
    } catch (e) {
      this.$store.commit(
        "SHOW_NOTIFICATION",
        `Error loading instance id=${to.params.id}; ${e}`
      );
    } finally {
      this.$store.commit("HIDE_PROGRESS");
      next();
    }
  },
  methods: {
    updateNegotiatorOption(payload) {
      this.ic.negotiatorOptionTitle = payload.title;
      this.ic.negotiatorOptionContent = payload.content;
      this.ic.negotiatorOptionButton = payload.button;
    },
    loadView() {
      this.ic.instanceId = this.$route.params.id;
      this.ic.negotiatorOptionTitle = this.$store.state.instanceConfig.negotiatorOptionTitle;
      this.ic.negotiatorOptionContent = this.$store.state.instanceConfig.negotiatorOptionContent;
      this.ic.negotiatorOptionButton = this.$store.state.instanceConfig.negotiatorOptionButton;
    },
    async saveNext() {
      await this.save();
      this.$router.push({ name: "start" });
    }
  }
};
</script>
